import * as actions from './actions';
import { useSolverWorker, useAlgorithmInfo } from "../hooks";

// Example URL, replace with the actual endpoint

export const fetchData = ({id, token, callback}) => {

    
  return async (dispatch) => {
    try {
      const response = await fetch(actions.DATA_URL+'getshipmentdata?id='+id+'&token='+token);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();

      // Assuming data contains { points: [], viewport: { latitude, longitude, zoom } }
      const { points, viewport, bestPath, cost } = data;
     
      if( points && viewport){
        dispatch(actions.setPointsAction(points));
        dispatch(actions.setViewportState(viewport));

        if(bestPath && bestPath.length>0)
        dispatch(actions.setBestPath(bestPath, cost))

        
        callback(true)
      }else{
        callback(false)
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      callback(false)
    }
  };

  return <></>
};
