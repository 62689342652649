import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AlgorithmModals,
  IntroductionModal,
  Layout,
  MapPlot,
  Menu,
  SEO,
  ThemeToggle
} from "../components";
import { useSolverWorker, useAlgorithmInfo } from "../hooks";
import * as selectors from "../store/selectors";
import * as actions from "../store/actions";
import { fetchData } from '../store/fetch';
import logo from './backend-logo.png'
import { ToastProvider, useToasts } from 'react-toast-notifications';

const IndexPage = () => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const visibilityCallback=(val)=>{

    setIsVisible(val)
  }
  const algorithm = useSelector(selectors.selectAlgorithm);
  const algorithmInfo = useAlgorithmInfo();
  const delay = useSelector(selectors.selectDelay);
  const evaluatingDetailLevel = useSelector(
    selectors.selectEvaluatingDetailLevel
  );
  const points = useSelector(selectors.selectPoints);
  const pointCount = useSelector(selectors.selectPointCount);
  const definingPoints = useSelector(selectors.selectDefiningPoints);

  const [isVisible, setIsVisible]=useState(false)
  const solver = useSolverWorker(dispatch, algorithm);

  const onRandomizePoints = useCallback(() => {
    if (!definingPoints) {
      const bounds = mapRef.current.getBounds();
      dispatch(actions.randomizePoints(bounds, pointCount));
    }
  }, [mapRef, dispatch, pointCount, definingPoints]);

  const start = useCallback(() => {
    dispatch(actions.startSolving(points, delay, evaluatingDetailLevel));
    solver.postMessage(
      actions.startSolvingAction(points, delay, evaluatingDetailLevel)
    );
  }, [solver, dispatch, delay, points, evaluatingDetailLevel]);

  const fullSpeed = useCallback(() => {
    dispatch(actions.goFullSpeed());
    solver.postMessage(actions.goFullSpeed());
  }, [solver, dispatch]);

  const pause = useCallback(() => {
    dispatch(actions.pause());
    solver.postMessage(actions.pause());
  }, [solver, dispatch]);

  const unpause = useCallback(() => {
    dispatch(actions.unpause());
    solver.postMessage(actions.unpause());
  }, [solver, dispatch]);

  const stop = useCallback(() => {
    dispatch(actions.stopSolving());
    solver.terminate();
  }, [solver, dispatch]);


  useEffect(()=>{

  


    
    if (actions.idValue && actions.tokenValue) {
      console.log(`The query parameter 'id' is present with value: ${actions.idValue}`);
      dispatch(fetchData({id:actions.idValue,token:actions.tokenValue, callback:visibilityCallback}));
      //setIsVisible(true)
    } else {
      console.log(`The query parameter 'id' is not present.`);
      // Handle the case where the parameter is not present
      setIsVisible(false)
    }

   

  },[])

  useEffect(() => {
    solver.postMessage(actions.setDelay(delay));
  }, [delay, solver]);



  useEffect(() => {
    solver.postMessage(actions.setEvaluatingDetailLevel(evaluatingDetailLevel));
  }, [evaluatingDetailLevel, solver]);

  const algTitle = useMemo(() => {
    const alg = algorithmInfo.find(alg => alg.solverKey === algorithm);
    return alg.friendlyName;
  }, [algorithm, algorithmInfo]);


  /* const FormWithToasts = ({}) => {
    const { addToast } = useToasts();
    const [showToast, setShowToast]=useState(1)
    useEffect(() => {
      if(actions.toast!=showToast)setShowToast(actions.toast)
    }, [actions.toast]);

    const handleToast=()=>{
      setShowToast(1)
      addToast('Data Saved Successfully',  { appearance: 'success', autoDismiss:true });
    
    }
  
    const showToastC=useEffect(()=>{

      if(showToast!==1){
        handleToast()
      
       }
       console.log(showToast)
    },[showToast])
  
    return <></>;
  };
   */

  return (
    <Layout>
      <SEO subtitle={algTitle} />
      {isVisible &&
      <><IntroductionModal />
      <AlgorithmModals />
      <Menu
        onStart={start}
        onPause={pause}
        onUnPause={unpause}
        onFullSpeed={fullSpeed}
        onStop={stop}
        onRandomizePoints={onRandomizePoints}
        logo={logo}
      />
       <MapPlot ref={mapRef}></MapPlot>
      </>
      }


{!isVisible &&

<div style={{textAlign:"center", width:"100%", padding:"12px"} } >
  <img src={logo} width="100"/>
  <h6>Chargement de données de Medpharma en cours ....</h6>
</div>
}

{/* <ToastProvider>
    <FormWithToasts  />
  </ToastProvider> */}
    </Layout>
  );
};

export default IndexPage;
